import React, { useEffect, useState } from "react";
import { Typography, Box, styled } from "@mui/material";
import ky from "ky";

import Footer from "./Footer";
import Header from "./Header";

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
});

const StyledContainer = styled(Box)(({ theme }) => ({
  maxWidth: 600,
  margin: "0 auto",
  padding: theme.spacing(3),
  textAlign: "center",
  fontFamily: "'Comic Sans MS', 'Comic Sans', cursive",
  color: "#333",
  flexGrow: 1,
}));

const AddToSlackButton = styled("img")({
  height: 40,
  width: 139,
  margin: "20px 0",
});

const FriendlyText = styled(Typography)(() => ({
  color: "#4CAF50",
  fontFamily: "'Advent Pro', sans-serif",
}));

const Install = () => {
  const [slackLink, setSlackLink] = useState("");

  useEffect(() => {
    ky.get(window._env_.REACT_APP_API_URL + "/slack/install")
      .text()
      .then((data) => {
        setSlackLink(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    <MainContainer>
      <Header />
      <Box sx={{ flex: 1 }}>
        <StyledContainer>
          <FriendlyText variant="h4">
            <strong>Click the button below to get started!</strong>
          </FriendlyText>
          {slackLink && (
            <div className="container">
              <span className="circle">
                <span className="fa fa-arrow-down" />

                <a href={slackLink} target="_blank" rel="noreferrer">
                  <AddToSlackButton
                    alt="Add to Slack"
                    src="https://platform.slack-edge.com/img/add_to_slack.png"
                    srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                  />
                </a>
              </span>
            </div>
          )}
          <Box textAlign="left" mt={4}>
            <Typography variant="h4" gutterBottom>
              How to Install
            </Typography>
            <Typography variant="body1">
              To install Buddy Check, follow these steps:
              <ol>
                <li>
                  Click the "Add to Slack" button below and follow the prompts
                  provided by Slack to install the app to your account.
                </li>
                {slackLink ? (
                  <a href={slackLink} target="_blank" rel="noreferrer">
                    <img
                      alt="Add to Slack"
                      height="40"
                      width="139"
                      src="https://platform.slack-edge.com/img/add_to_slack.png"
                      srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                    />
                  </a>
                ) : (
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{ border: "solid 3px black", borderRadius: "10px" }}
                  >
                    <strong>
                      Installation not currently available.
                      <br /> Please refresh to try again or reach out to us on
                      our Support page!
                    </strong>
                  </Typography>
                )}
                <li>Once the app is installed to your account...</li>
                <ol>
                  <li>Look for the DM from the Buddy Check bot.</li>
                  <li>
                    Follow the prompts within the DM and provide Buddy Check
                    with your birthdate
                  </li>
                  <li>Enjoy Slack Safely!</li>
                </ol>
              </ol>
            </Typography>
          </Box>
        </StyledContainer>
      </Box>
      <Footer />
    </MainContainer>
  );
};

export default Install;
