import { AppBar, styled, Toolbar, Typography } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

const StyledToolbar = styled(Toolbar)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.common.white,
  marginLeft: 15,
  textDecoration: "none",
  position: "relative",
  "&::after": {
    content: '""',
    display: "block",
    width: "100%",
    height: "2px",
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: "-2px",
    left: "0",
    transform: "scaleX(0)",
    transformOrigin: "bottom right",
    transition: "transform 0.3s ease",
  },
  "&:hover::after": {
    transform: "scaleX(1)",
    transformOrigin: "bottom left",
  },
}));

const Header = () => {
  const location = useLocation();
  return (
    <StyledAppBar position="static">
      <StyledToolbar>
        <Typography
          variant="h6"
          component="div"
          sx={{ fontFamily: "'Advent Pro', sans-serif" }}
        >
          <StyledLink to="/" sx={{ textDecoration: "none" }}>
            <strong>Buddy Check</strong>
          </StyledLink>
        </Typography>
        <nav>
          {location.pathname !== "/" && <StyledLink to="/">Home</StyledLink>}
          {location.pathname !== "/install" && (
            <StyledLink to="/install">
              <strong>Get Started</strong>
            </StyledLink>
          )}
          {location.pathname !== "/support" && (
            <StyledLink to="/support">Support</StyledLink>
          )}
        </nav>
      </StyledToolbar>
    </StyledAppBar>
  );
};
export default Header;
