import React from "react";
import { Typography, styled, Box } from "@mui/material";

import Header from "./Header";
import Footer from "./Footer";

const StyledContainer = styled(Box)(({ theme }) => ({
  maxWidth: 600,
  margin: "0 auto",
  padding: theme.spacing(3),
}));

const Home = () => {
  return (
    <>
      <Header />
      <StyledContainer>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img alt="logo" height={250} width={250} src="./logo.png" />
        </div>
        <Typography variant="h2" align="center">
          Welcome to
        </Typography>
        <Typography
          variant="h2"
          align="center"
          sx={{ fontFamily: "'Advent Pro', sans-serif" }}
        >
          <strong>Buddy Check!</strong>
        </Typography>
        <Typography variant="body1" align="center" mb={5}>
          (formerly known as Tattletale)
        </Typography>
        <Typography variant="body1" paragraph>
          Buddy Check is a Slack application that helps you monitor and manage
          your team's activities. It is designed to help ensure YPT compliance
          is maintained in online Slack workspaces. Install Buddy Check on your
          Slack workspace to get started.
        </Typography>
        <Typography variant="h4" gutterBottom>
          How does Buddy Check Work?
        </Typography>
        <Typography variant="body1">
          <Typography variant="h6">Buddy Check works by:</Typography>
          <ol>
            <li>Installing Buddy Check for every member in your workspace.</li>
            <li>Collecting every user's birthdate.</li>
            <li>Intercepting every message sent in Slack.</li>
            <li>
              Checking if the intercepted public channel message, private
              channel message, group message, or DM violates YPT.
            </li>
            <li>A message can violate YPT in 3 ways:</li>
            <ol>
              <li>A chat does not include any adults (18+)</li>
              <li>A chat is 1-1 between a youth and adult</li>
              <li>A chat includes members not registered with Buddy Check</li>
            </ol>
            <li>
              If any of these occur, the sent message is deleted and replaced
              with a warning that instructs the user on how to remedy the
              mistake.
            </li>
            <ul>
              <li>Example Warning Messages: </li>
              <ul>
                <li>
                  <strong>Warning from Buddy Check:</strong>Your message
                  violates YPT! Please include a parent or leader over the age
                  of 18 in every message!
                </li>
                <li>
                  <strong>Warning from Buddy Check:</strong>Your message
                  violates YPT! 1-1 contact between an adult and a youth is not
                  permitted. Please ensure every conversation that includes
                  youth has at least 3 people. 1 of which must be over the age
                  of 18!
                </li>
                <li>
                  <strong>Warning from Buddy Check:</strong>Your message may
                  violate YPT! One or more members of this DM are not registered
                  with Buddy Check. Therefore we do not know their age. Please
                  ensure you're registered to be able to send messages.
                </li>
              </ul>
            </ul>
          </ol>
        </Typography>
      </StyledContainer>
      <Footer />
    </>
  );
};

export default Home;
