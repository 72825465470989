import React from "react";
import { Typography, Box, styled } from "@mui/material";

import Footer from "./Footer";
import Header from "./Header";

const StyledContainer = styled(Box)(({ theme }) => ({
  maxWidth: 600,
  margin: "0 auto",
  padding: theme.spacing(3),
}));
const StyledPrivacyPolicy = styled(Box)(() => ({
  whiteSpace: "pre-wrap",
  overflowWrap: "break-word",
}));
const privacyPolicyContent = `
These Terms of Service ("Terms") govern your use of the Buddy Check Slack application ("Buddy Check" or the "Application") provided by Jacob Byerline ("we," "us," or "our"). By using Buddy Check, you agree to be bound by these Terms. If you do not agree to these Terms, please refrain from using the Application.

Use of Buddy Check:
1.1. Eligibility: You must be at least 14 years old or have the legal capacity to enter into a binding agreement to use Buddy Check.
1.2. License: Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, and revocable license to use Buddy Check for its intended purpose.

User Responsibilities:
2.1. Compliance: You agree to use Buddy Check in compliance with all applicable laws, regulations, and these Terms.
2.2. User Content: You are solely responsible for any content you submit, display, or transmit through Buddy Check. You retain ownership of your content and grant us a non-exclusive license to use, modify, display, reproduce, and distribute it as necessary for the provision of Buddy Check.
2.3. Prohibited Activities: You must not engage in any activity that may disrupt or interfere with the proper functioning of Buddy Check or violate the rights of others. This includes but is not limited to unauthorized access, distribution of harmful content, spamming, or engaging in any illegal or malicious activities.

Intellectual Property:
3.1. Ownership: We retain all rights, title, and interest in and to Buddy Check, including all intellectual property rights. You acknowledge that Buddy Check is protected by copyright, trademark, and other laws.
3.2. Limited Use: You are not allowed to use our trademarks, logos, or any other proprietary information without our prior written consent.

Privacy:
4.1. Data Collection: We collect and process personal information in accordance with our Privacy Policy. By using Buddy Check, you consent to our collection, use, and disclosure of your personal information as described in the Privacy Policy.
4.2. Workspace Data: Buddy Check operates within Slack workspaces and may have access to certain workspace data. We handle this data in accordance with our Privacy Policy and solely for the purpose of ensuring Boy Scouts of America's Youth Protection Training compliance within the Slack workspace.

Limitation of Liability:
5.1. Disclaimer: Buddy Check is provided "as is" without warranties of any kind, whether express or implied. We do not guarantee the accuracy, reliability, or availability of Buddy Check and disclaim any liability for any errors or omissions in the Application.
5.2. Indemnification: You agree to indemnify and hold us harmless from any claims, damages, losses, or liabilities arising out of your use of Buddy Check or violation of these Terms.
5.3. Limitation of Liability: To the extent permitted by law, we shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of Buddy Check.

Modifications and Termination:
6.1. Modification of Terms: We reserve the right to modify these Terms at any time. Any changes will be effective upon posting the revised Terms on our website or within Buddy Check. Your continued use of Buddy Check constitutes your acceptance of the modified Terms.
6.2. Termination: We may suspend or terminate your access to Buddy Check at any time and for any reason, without prior notice.

Governing Law:
These Terms shall be governed by and construed in accordance with the laws of California. Any disputes arising out of or relating to these Terms or Buddy Check shall be subject to the exclusive jurisdiction of the courts located in California.

Contact Us:
If you have any questions or concerns about these Terms, please contact us using our support page on our website.
Last updated: 5/31/2023
  `;
const TermsOfService = () => {
  return (
    <>
      <Header />
      <StyledContainer>
        <Typography variant="h2" gutterBottom>
          Terms of Service
        </Typography>
        <StyledPrivacyPolicy>
          <Typography variant="body1">{privacyPolicyContent}</Typography>
        </StyledPrivacyPolicy>
      </StyledContainer>
      <Footer />
    </>
  );
};

export default TermsOfService;
