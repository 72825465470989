import React from "react";
import { Typography, Box, styled } from "@mui/material";

import Header from "./Header";
import Footer from "./Footer";

const StyledContainer = styled(Box)(({ theme }) => ({
  maxWidth: 600,
  margin: "0 auto",
  padding: theme.spacing(3),
}));
const StyledPrivacyPolicy = styled(Box)(() => ({
  whiteSpace: "pre-wrap",
  overflowWrap: "break-word",
}));
const privacyPolicyContent = `
At Buddy Check, we are committed to protecting the privacy and security of your information. This Privacy Policy explains how we collect, use, and disclose your personal data when you use our Slack application, Buddy Check, which is designed to ensure Boy Scouts of America's Youth Protection Training (YPT) compliance within Slack workspaces. We encourage you to read this Privacy Policy carefully to understand our practices regarding your personal information.

1. Information We Collect:
    We may collect and process the following categories of personal information:

    1.1. Information provided by You: When you use Buddy Check, we may collect and store the information you provide, such as your name, email address, and any other information you voluntarily choose to provide through the application.

    1.2. Workspace Data: Buddy Check operates within Slack workspaces and may have access to certain workspace data, including channel messages, user information, and other data relevant to ensuring YPT compliance. We only collect and process this workspace data to fulfill the intended functionality of Buddy Check.

2. How We Use Your Information:
    We use the collected information for the following purposes:

    2.1. YPT Compliance: We process the information collected to help ensure Boy Scouts of America's Youth Protection Training compliance within Slack workspaces. This includes identifying and reporting any potentially inappropriate content or behavior related to youth protection.

    2.2. Communication: We may use your contact information to communicate with you regarding updates, enhancements, or changes to the Buddy Check application.

    2.3. Improving Our Services: We may analyze the usage patterns and behavior of Buddy Check users to improve and optimize our application's functionality and performance.

3. Data Retention:
    We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. When your information is no longer needed, we will securely delete or anonymize it.

4. Data Sharing and Disclosure:
    We do not sell or rent your personal information to third parties. However, we may share your information in the following circumstances:

    4.1. With Your Consent: We may share your personal information with third parties if you have provided your consent to do so.

    4.2. Service Providers: We may engage trusted third-party service providers to assist us in operating and improving Buddy Check. These service providers have access to your information only to perform specific tasks on our behalf and are obligated to maintain the confidentiality and security of your information.

    4.3. Legal Compliance: We may disclose your personal information if required to do so by law or in response to valid legal requests from public authorities.

5. Data Security:
    We take reasonable measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission or storage is completely secure, and we cannot guarantee the absolute security of your data.

6. Your Rights:
    You have certain rights regarding your personal information, including the right to access, correct, or delete the information we hold about you. If you have any questions or would like to exercise your rights, please contact us using the information provided below.

7. Updates to this Privacy Policy:
    We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. We will post the updated version on our website or provide you with notice through Buddy Check. We encourage you to review this Privacy Policy periodically.

8. Contact Us:
    If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us using the support page on our website.

    By using Buddy Check, you acknowledge that you have read and understood this Privacy Policy and agree to the collection, use, and disclosure of your personal information as described herein.

    Last updated: 5/31/2023
  `;
const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <StyledContainer>
        <Typography variant="h2" gutterBottom>
          Privacy Policy
        </Typography>
        <StyledPrivacyPolicy>
          <Typography variant="body1">{privacyPolicyContent}</Typography>
        </StyledPrivacyPolicy>
      </StyledContainer>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
