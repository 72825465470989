import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./components/Home";
import Support from "./components/Support";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import Install from "./components/Install";

const App = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/install" element={<Install />} />
      <Route path="/support" element={<Support />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/tos" element={<TermsOfService />} />
    </Routes>
  );
};

export default App;
