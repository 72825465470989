import { Box, Container, Grid, styled, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.common.white,
  marginLeft: 15,
  textDecoration: "none",
  position: "relative",
  "&::after": {
    content: '""',
    display: "block",
    width: "100%",
    height: "2px",
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: "-2px",
    left: "0",
    transform: "scaleX(0)",
    transformOrigin: "bottom right",
    transition: "transform 0.3s ease",
  },
  "&:hover::after": {
    transform: "scaleX(1)",
    transformOrigin: "bottom left",
  },
}));

export const Footer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "primary.main",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        bottom: 0,
        left: 0,
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={12}>
            <Typography variant="body1">
              <nav>
                <StyledLink to="/privacy-policy">Privacy Policy</StyledLink>
                <StyledLink to="/tos">Terms of Service</StyledLink>
              </nav>
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "-15px" }}>
            <StyledLink
              to="https://byerline.me"
              target="_blank"
              rel="noreferrer"
            >
              <Typography color="primary.contrastText" variant="body2">
                {`© Jacob Byerline ${new Date().getFullYear()}`}
              </Typography>
            </StyledLink>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
