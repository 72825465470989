import React, { useState } from "react";
import {
  Typography,
  Box,
  Button,
  TextField,
  Container,
  Stack,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/system";
import ky from "ky";

import Footer from "./Footer";
import Header from "./Header";

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
});

const StyledContainer = styled(Box)(({ theme }) => ({
  maxWidth: 600,
  margin: "0 auto",
  padding: theme.spacing(3),
}));

const Support = () => {
  const [contactDetails, setContactDetails] = useState({
    user_name: "",
    user_email: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleChange = (e) => {
    setContactDetails({ ...contactDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { user_name, user_email, message } = contactDetails;

    // Format the message you want to send to Slack
    const slackMessage = {
      text: `New Inquiry from Buddy Check!\n*Name:* ${user_name}\n*Email:* ${user_email}\n*Message:* ${message}`,
    };

    setIsLoading(true);

    // Send the message to Slack using ky
    ky.post(
      "https://hooks.slack.com/services/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",
      {
        json: slackMessage,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      },
    )
      .then(() => {
        setSnackbar({
          open: true,
          message:
            "Your message has been sent to our support team, we will be in touch soon!",
          severity: "success",
        });
      })
      .catch((error) => {
        console.error("Failed to send message!", error);
        setSnackbar({
          open: true,
          message: "Failed to send your message. Please try again.",
          severity: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <MainContainer>
      <Header />
      <Box
        p={4}
        sx={{
          flex: 1,
        }}
      >
        <StyledContainer>
          <Container>
            <Stack direction="row" spacing={2}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  pb: 3,
                  pt: 1,
                }}
              >
                Contact Us
              </Typography>
            </Stack>
            <Typography variant="subtitle1" gutterBottom sx={{ pb: 0 }}>
              Reach out to us to get started or if you have any questions!
            </Typography>
            <Typography variant="subtitle1" gutterBottom sx={{ pb: 2 }}>
              Feel free to send up any feature requests or feedback as well :)
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Name"
                name="user_name"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                onChange={handleChange}
                value={contactDetails.user_name}
              />
              <TextField
                label="Email"
                name="user_email"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                onChange={handleChange}
                value={contactDetails.user_email}
              />
              <TextField
                label="Message"
                name="message"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                multiline
                rows={4}
                onChange={handleChange}
                value={contactDetails.message}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 3 }}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : "Send"}
              </Button>
            </form>
          </Container>

          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleCloseSnackbar}
              severity={snackbar.severity}
            >
              {snackbar.message}
            </MuiAlert>
          </Snackbar>
        </StyledContainer>
      </Box>
      <Footer />
    </MainContainer>
  );
};

export default Support;
